import * as React from "react"
import { graphql } from "gatsby"

import { Box, 
  Container,
} from "@chakra-ui/react"

  import Seo from "../components/seo"
  import BackgroundImage from "../components/Backgrounds/BackgroundImage"
  import SidebarNav from "../components/SidebarNav/SidebarNav"
  import MainContentBox from "../components/MainContentBox/MainContentBox"
  import MobileNav from "../components/MobileNav/MobileNav"
  import Footer from "../components/Footer/Footer"
  import OurWorkPageHero  from "../components/PageHero/OurWorkPageHero"
  import SliceZone from "../components/SliceZone/SliceZone"
  import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
  import { ChevronRightIcon } from "@chakra-ui/icons"
  import { getImage } from "gatsby-plugin-image"
  import HighlightsSliderBlock from "../components/HighlightsSlider/HighlightsSliderBlock"
import Testimonials from "../components/Testimonials/Testimonials"

  const OurWork = ({ pageContext, location, data }) => {
    const pageLocation = location.pathname
    const {
      breadcrumb: { crumbs },
    } = pageContext

    if (!data) return null
    const document = data.allPrismicPage.edges[0].node.data
    
    const backgroundImage = data.allPrismicPage.edges[0].node.data.background_image.localFile

      const heroContent = {
      title: document.page_title.text,
      description: document.page_description.text,
      ctaButtons: document.cta_buttons,
      //featuredImage: document.featured_images[0]?.featured_image.localFile
    }
    const customCrumbLabel = heroContent.title
    return (
      <>
      <Seo title="Teton Valley Advocates | Our Work" />
      <BackgroundImage
        backgroundImage={getImage(backgroundImage)}
      >
        <Container maxW="container.xl">
          <MobileNav />
          <Box paddingTop="20" width="100%">
              <SidebarNav 
                pathName={pageLocation}
              />
          <MainContentBox>
             <OurWorkPageHero 
              title={heroContent.title}
              description={heroContent.description}
              buttons={heroContent.ctaButtons}
              location={location}
              //featuredImage={heroContent.featuredImage}
              
            />
        <Box p="4" backgroundColor="rgba(255, 255, 255, 0.8)" textAlign="center">
            <Breadcrumb
              crumbs={crumbs}
              crumbSeparator={<ChevronRightIcon />}
              crumbLabel={customCrumbLabel}
          />
          </Box>
          <Box>
            <SliceZone sliceZone={document.body} />
          </Box>
          {/* <BlogPostsBlock title="Facebook Block..." />       */}
            <Footer />
          </MainContentBox>
          </Box>

        </Container>

    </BackgroundImage>
      </>
    )
  }

  export const query = graphql`
  query OurWork {
    allPrismicPage(filter: {uid: {eq: "our-work"}}) {
      edges {
        node {
          data {
            page_title {
              text
            }
            page_description {
              text
            }
            featured_images {
              featured_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: AUTO)
                  }
                }
              }
            }
            cta_buttons {
              button_color
              cta_button_link {
                uid
              }
              cta_button_label {
                text
              }
            }
            body {
              ... on PrismicPageDataBodyOurWorkCards {
                id
                items {
                  featured_project
                  brief_project_description {
                    text
                  }
                  project_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, aspectRatio: 1.5)
                      }
                    }
                    alt
                  }
                  project_title {
                    text
                  }
                  project_link {
                    uid
                    url
                  }
                }
                slice_type
              }
              ... on PrismicPageDataBodyCallToAction {
                id
                slice_type
                items {
                  button_color
                  button_label
                  button_link {
                    uid
                  }
                }
                
                primary {
                  title {
                    text
                    raw
                  }
                  paragraph {
                    text
                    raw
                  }
                  background_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(formats: AUTO, layout: FULL_WIDTH, placeholder: BLURRED)
                      }
                    }
                  }
                }
              }
              ... on PrismicPageDataBodyNewsletterSignUp {
                id
                slice_type
                primary {
                  button_color
                  button_text {
                    text
                  }
                  paragraph {
                    text
                  }
                  title {
                    text
                  }
                }
              }
              ... on PrismicPageDataBodyOurWorkTabs {
                id
                slice_type
                items {
                  tab_content {
                    raw
                  }
                  tab_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: FULL_WIDTH
                          formats: AUTO
                          placeholder: BLURRED
                          quality: 60
                        )
                      }
                    }
                    alt
                  }
                  tab_subtitle {
                    text
                  }
                  tab_title {
                    text
                  }
                }
                primary {
                  tab_section_subtitle {
                    text
                  }
                  tab_section_title {
                    text
                  }
                }
              }
              ... on PrismicPageDataBodyBlogPostContent {
                id
                slice_type
                items {
                  post_content {
                    raw
                  }
                }
              }
            }
            background_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, formats: AUTO, placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
  }  
`

  export default OurWork
