import React from 'react'
import {
    Box,
    Container,
    Flex,
    Text
} from '@chakra-ui/react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import styled from '@emotion/styled'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import CtaButton from '../Buttons/CtaButton'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { ChevronRightIcon } from '@chakra-ui/icons'

const ImageGridItem = styled(Box)`
    position: relative;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
`;

const TextItem = styled(Box)`
    position: absolute;
    width: 100%;
    text-align: center;
    
    h3 {
       font-size: 2rem;
       font-weight: 600;
       line-height: 2rem;
       margin-bottom: .8rem;
    }
    .member-title {
        font-family: 'Open Sans', sans-serif;
        color: #eab117;
        font-weight: 600;
        font-size: 1rem;
        text-transform: uppercase;
    }
`

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      gap: 0
      //slidesToSlide: 4 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

const HighlightsSliderBlock = () => {
    const data = useStaticQuery(graphql`
    {
        allPrismicHighlightsSlider {
          edges {
            node {
              data {
                slide {
                  title {
                    text
                  }
                  link {
                    uid
                  }
                  parent_page {
                    uid
                  }
                  before_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: CONSTRAINED
                          placeholder: BLURRED
                          transformOptions: {cropFocus: CENTER}
                        )
                      }
                    }
                    alt
                  }
                  after_image {
                    alt
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          transformOptions: {cropFocus: CENTER}
                          placeholder: BLURRED
                          layout: CONSTRAINED
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
  `)

  const items = data.allPrismicHighlightsSlider.edges[0].node.data.slide
    return (
        <Box>
            <Container maxW="container.lg">
        <Carousel
            swipeable={true}
            showDots={false}
            responsive={responsive}
            ssr={true}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={5000}
            centerMode={false}
            showDots
            //containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
        >
            {items.map((item) => {
                return (
                <Box>
                    <ImageGridItem w="100%" h="100%" m="0">
                        <Flex flexWrap="noWrap">
                            <Box p="4">
                                <GatsbyImage image={getImage(item.before_image.localFile)} alt={item.alt}/>
                            </Box>
                            <Box p="4">
                                <GatsbyImage image={getImage(item.after_image.localFile)} alt={item.alt}/>
                            </Box>
                        </Flex>
                        <Box textAlign="center" mb={8}>
                          <Text fontSize={{md: '1rem', lg: '1.25rem'}} fontWeight="600">
                          <Link to={`${item.parent_page.uid}/${item.link.uid}`}>
                            {<>{item.title.text} <ChevronRightIcon /></>}
                          </Link>
                          </Text>
                        {/* <CtaButton 
                          linkTo={`${item.parent_page.uid}/${item.link.uid}`}
                          size="md"
                          colorScheme="sunset"
                          btnText={<>{item.title.text} <ChevronRightIcon /></>}
                          textAlign="center"
                        /> */}
                        </Box>
                    </ImageGridItem>             
                </Box>
                )
            })}
       </Carousel>
       </Container>
        </Box>
    )
}

export default HighlightsSliderBlock