import React from "react"
import TwentyYearsStrong from "../HomePage/TwentyYearsStrong"
import CtaButton from "../Buttons/CtaButton"
import DonateButton from "../Buttons/DonateButton"

import { Box, Center, Flex, Text, Heading, Container } from "@chakra-ui/react"
import HighlightsSliderBlock from "../HighlightsSlider/HighlightsSliderBlock"

const OurWorkPageHero = props => {
  
  return (
    <>
      <Box
        mb={{ base: "-9px", md: "-15px" }}
        ml="10px"
        maxW={{ sm: "320px", md: "600px" }}
      >
        <TwentyYearsStrong width="100%" />
      </Box>
      <Box backgroundColor="rgba(255, 255, 255, 0.8)" marginBottom="4">
        <Box textAlign="right" padding="2">
        </Box>
        <Box padding="16px">
          <Box>
            <Heading
              as="h1"
              color="red.400"
              textAlign="center"
              marginBottom="4"
              fontSize={{ sm: "1.5rem", md: "2rem" }}
            >
              {props.title}
            </Heading>
          </Box>

          <Box>
            <Container maxW="container.sm">
            <Text
              color="blue.700"
              textAlign="center"
              fontSize={{ base: "1rem", md: "1.25rem " }}
            >
              {props.description}
            </Text>
            </Container>
          </Box>
          <Box>
              <HighlightsSliderBlock />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default OurWorkPageHero
